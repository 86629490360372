import Box from '@/gf/components/Box'
import { useRfqContext } from './context'
import Phone from '@/gf/modules/Phone'
import Urls from '@/dealers/modules/Urls'
import Link from '@/gf/components/Link'

const ContactCustomer = () => {
  const { rfq } = useRfqContext()

  const address = rfq.user.organization.billingAddress ?? rfq.shippingAddress
  const phoneNumber =
    rfq.assignedUser?.phoneNumber ?? rfq.user.phoneNumber ?? rfq.user.organization.phoneNumber

  return (
    <Box className="p-4 shadow-base bg-gray-50">
      <h3 className="font-medium text-center text-lg">Are parts backordered?</h3>
      <p className="text-center text-base mt-2">
        If any parts are out of stock, call or message your customer to let them know.
      </p>
      <p className="text-center text-base mt-2">
        If you no longer stock the parts, select Can&apos;t Participate in Order below.
      </p>

      <Box className="p-4 mt-6">
        {rfq.user.organization.logoUrl && (
          <img
            src={rfq.user.organization.logoUrl}
            alt=""
            className="w-12 h-12 block object-contain rounded-full border overflow-hidden"
          />
        )}
        <h4 className="text-base font-medium">{rfq.user.organization.name}</h4>
        {address && (
          <p className="text-sm">
            {address.city}, {address.state}
          </p>
        )}
        <div className="flex items-center justify-between gap-x-2">
          {phoneNumber && <p className="text-gray-700 font-medium">{Phone.format(phoneNumber)}</p>}
          <Link.S to={Urls.requestForQuoteMessages(rfq.id, { customer: true })}>Message</Link.S>
        </div>
      </Box>
    </Box>
  )
}

export default ContactCustomer

import { RequestForQuoteStep, useRfqAssignToUserMutation } from '@/dealers/_gen/gql'
import useReloadSession from '@/dealers/hooks/useReloadSession'
import useSession from '@/dealers/hooks/useSession'
import useMsgs from '@/gf/hooks/useMsgs'
import useToggle from '@/gf/hooks/useToggle'
import { Route, Routes } from 'react-router-dom'
import { BooleanParam, useQueryParam, withDefault } from 'use-query-params'
import { useRfqContext } from './context'
import CannotParticipate from './Details/CannotParticipate'
import CloseQuote from './Details/CloseQuote'
import CompleteOrder from './Details/CompleteOrder'
import CreateQuote from './Details/CreateQuote'
import EditQuote from './Details/EditQuote'
import QuoteSubmittedClaimAccountModal from './Details/QuoteSubmittedClaimAccountModal'
import QuoteSubmittedReminderModal from './Details/QuoteSubmittedReminderModal'
import Refund from './Details/Refund'
import RequestCancelled from './Details/RequestCancelled'
import Shipment from './Details/Shipment'
import UploadPod from './Details/UploadPod'
import ViewQuote from './Details/ViewQuote'
import QuoteUploadModal from './QuoteUpload/QuoteUploadModal'
import useQuoteUpload from './QuoteUpload/useQuoteUpload'
import useModal from './useModal'

const Details = ({
  loading: _loading,
  editMode = false,
}: {
  loading: boolean
  editMode?: boolean
}) => {
  const { rfq, refetch } = useRfqContext()
  const { user } = useSession()
  const reloadSession = useReloadSession()
  const [claimAccountModalOpen, claimAccountModal] = useToggle()
  const [_, msgr] = useMsgs()
  const { onReadyForPickup, onCreateShipping, onAcceptQuote } = useModal()
  const [isEditing, setIsEditing] = useQueryParam('edit', withDefault(BooleanParam, false))
  const [quoteSubmitted, setQuoteSubmitted] = useQueryParam(
    'quoteSubmitted',
    withDefault(BooleanParam, false)
  )
  const quoteUpload = useQuoteUpload()

  const [assignRfqToUser] = useRfqAssignToUserMutation({
    variables: { rfqId: rfq.id, ownerId: user.id, replace: true },
  })

  const exitEditMode = () => setIsEditing(undefined, 'replaceIn')

  const onQuoteSuccess = async () => {
    if (isEditing) exitEditMode()

    try {
      if (!editMode && !rfq.assignedUser) await assignRfqToUser()
    } catch (err) {
      console.error(err)
    }

    await refetch()

    if (!user.claimed) {
      claimAccountModal.on()
    } else {
      setQuoteSubmitted(true, 'replaceIn')
      msgr.add('Quote Submitted', 'positive')
    }
  }

  return (
    <>
      <QuoteSubmittedClaimAccountModal
        open={claimAccountModalOpen}
        onClose={claimAccountModal.off}
        onAccountClaimed={reloadSession}
        rfqCreatedAt={rfq.insertedAt}
      />

      <QuoteSubmittedReminderModal
        open={quoteSubmitted}
        onClose={() => setQuoteSubmitted(undefined, 'replaceIn')}
        rfqId={rfq.id}
      />

      <QuoteUploadModal
        uploadStage={quoteUpload.uploadStage}
        error={quoteUpload.error}
        onClose={quoteUpload.onDismiss}
      />

      {!rfq.quote && rfq.step === RequestForQuoteStep.Inbound ? (
        <CreateQuote
          onQuoteSuccess={onQuoteSuccess}
          quoteUpload={quoteUpload}
          onReadyForPickup={onReadyForPickup}
          onCreateShipping={onCreateShipping}
          onAcceptQuote={onAcceptQuote}
        />
      ) : editMode && rfq.quote ? (
        <EditQuote
          onQuoteSuccess={onQuoteSuccess}
          exitEditMode={exitEditMode}
          quoteUpload={quoteUpload}
          onCreateShipping={onCreateShipping}
          onReadyForPickup={onReadyForPickup}
          onAcceptQuote={onAcceptQuote}
        />
      ) : rfq.step === RequestForQuoteStep.Canceled && !rfq.quote ? (
        <RequestCancelled />
      ) : (
        <ViewQuote />
      )}

      <Routes>
        {rfq.quote && (
          <Route
            path="close-quote"
            element={<CloseQuote storeOrderId={rfq.quote.id} onClosed={refetch} />}
          />
        )}

        <Route
          path="cant-participate"
          element={<CannotParticipate rfq={rfq} onSubmitted={refetch} />}
        />

        <Route path="complete-order" element={<CompleteOrder />} />
        <Route path="upload-proof-of-delivery" element={<UploadPod />} />
        <Route path="refund" element={<Refund />} />
        <Route path="shipments/:id" element={<Shipment />} />
      </Routes>
    </>
  )
}

export default Details

import {
  DeliveryMethod,
  RequestForQuoteStep,
  StoreOrderState,
  useAddInvoiceToStoreOrderMutation,
} from '@/dealers/_gen/gql'
import { useRfqContext } from '../context'

import Box from '@/gf/components/Box'
import Action from '@/gf/components/Action'
import UploadButton from '@/gf/components/UploadButton'
import useToggle from '@/gf/hooks/useToggle'
import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { DocumentTextIcon } from '@heroicons/react/outline'
import useMsgs from '@/gf/hooks/useMsgs'

const InvoiceUpload = () => {
  const { rfq, invoiceSent, refetch } = useRfqContext()
  const [_, msgs] = useMsgs()
  const [uploading, uploadToggle] = useToggle()
  const [focus, setFocus] = useState(false)
  const [addInvoice] = useAddInvoiceToStoreOrderMutation()

  if (!rfq.quote) {
    return null
  }

  const storeOrderId = rfq.quote.id

  const canSendInvoice =
    (rfq.step !== RequestForQuoteStep.Fulfilling ||
      rfq.quote.deliveryMethod !== DeliveryMethod.Shipping ||
      rfq.quote.state !== StoreOrderState.PartiallyShipped) &&
    (rfq.step === RequestForQuoteStep.Fulfilling ||
      rfq.step === RequestForQuoteStep.Fulfilled ||
      rfq.step === RequestForQuoteStep.PoReceived) &&
    !invoiceSent

  if (!canSendInvoice) {
    return null
  }

  return (
    <Box className="px-6 py-4 flex items-center gap-x-2">
      <div className="space-y-2 flex-grow">
        <h4 className="text-base font-medium">Upload Invoice</h4>
        <p className="text-sm">Please upload your customer&apos;s invoice when it is ready.</p>
      </div>

      <UploadButton
        allowedFileTypes={['.pdf', 'application/pdf']}
        onUploadComplete={async (uploadedFiles) => {
          await addInvoice({
            variables: {
              invoiceUrl: uploadedFiles[0].url,
              storeOrderId,
              invoiceDueDate: null,
            },
          })

          msgs.add(
            "Invoice uploaded! We'll let your customer know the invoice is available.",
            'positive'
          )

          refetch()
          uploadToggle.off()

          return Promise.resolve()
        }}
        onUploadStarted={uploadToggle.on}
        onError={(err) => {
          msgs.add(
            'Something went wrong while uploading your invoice, please try again or contact support.',
            'negative'
          )

          uploadToggle.off()
          console.error(err)
        }}
        key="upload-invoice-button"
      >
        <div
          className={twMerge(
            'py-2 pl-2 pr-4 flex flex-row gap-x-2 items-center rounded-lg border-2 border-gray-200 border-dashed bg-gray-50',
            focus && 'border-gearflow border-solid'
          )}
          onDragLeave={() => setFocus(false)}
          onDragOver={() => setFocus(true)}
          onDrop={() => setFocus(false)}
        >
          <Action.P
            performing={uploading}
            className="font-medium text-sm flex items-center shrink-0"
          >
            <DocumentTextIcon className="mr-2 h-4 inline-block" /> Upload Invoice
          </Action.P>
          <span className="text-sm text-gray-500 font-medium leading-tight">
            or drag & drop here
          </span>
        </div>
      </UploadButton>
    </Box>
  )
}

export default InvoiceUpload

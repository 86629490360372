import {
  DeliveryMethod,
  PaymentMethod,
  RequestForQuoteQuery,
  RequestForQuoteStep,
  StoreOrderState,
} from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import Link from '@/gf/components/Link'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import ParticipateAction from './StepNote/ParticipateAction'
import useSession from '@/dealers/hooks/useSession'

type Rfq = NonNullable<RequestForQuoteQuery['requestForQuote']>

const formatTimestamp = (dateTime: DateTime) =>
  `${dateTime.toLocaleString(DateTime.DATE_SHORT)} at ${dateTime.toLocaleString(
    DateTime.TIME_SIMPLE
  )}`

const buildNote = ({
  rfq,
  deliveryMethod,
  invoiceSent,
  partiallyShipped,
}: {
  rfq: Rfq
  deliveryMethod: DeliveryMethod | undefined
  invoiceSent: boolean
  partiallyShipped: boolean
}) => {
  if (rfq.step === RequestForQuoteStep.Inbound) {
    const deniedAt = rfq.quote?.timeline.deniedAt ?? null
    const insertedAt = rfq.insertedAt ?? null
    const denialReason = rfq.quote?.timeline.denialReason ?? undefined
    const isPartialApproval = rfq.quote?.lineItems.some((li) => !!li.rejectedAt) ?? false

    return rfq.quote?.state === StoreOrderState.QuoteDenied
      ? {
          details: isPartialApproval ? (
            <>
              <p>Your quote was sent back because your customer requested changes.</p>
              <p>
                Please update shipping and tax information as needed before resubmitting your quote.
              </p>
            </>
          ) : (
            <>
              <p>The customer denied the quote with the following reason: {denialReason}.</p>
              <p>You can update the quote and send it back to the customer.</p>
            </>
          ),
          title: isPartialApproval ? 'Changes Requested' : 'Quote Denied',
          timestamp: deniedAt ? `Denied at: ${formatTimestamp(deniedAt)}` : null,
          classNames: {
            title: 'text-red-700 text-purple-700',
            box: 'border-purple-200 bg-purple-100',
          },
        }
      : {
          details: (
            <p>
              Add each part&apos;s price and check whether it&apos;s available now. You can also add
              sales tax (optional).
            </p>
          ),
          title: 'Inbound Request',
          timestamp: insertedAt ? `Created at: ${formatTimestamp(insertedAt)}` : null,
          classNames: {
            title: 'text-blue-700',
            box: 'border-blue-700 border-opacity-10 bg-blue-100',
          },
        }
  }

  if (rfq.step === RequestForQuoteStep.Quoted) {
    const quotedAt = rfq.quote?.insertedAt ?? null

    return {
      details: (
        <p>
          If your customer accepts your quote, you&apos;ll be notified with an approved purchase
          order.
        </p>
      ),
      title: 'Quoted',
      timestamp: quotedAt ? `Quoted at: ${formatTimestamp(quotedAt)}` : null,
      classNames: {
        title: 'text-green-700',
        box: 'border-green-200 bg-green-100',
      },
    }
  }

  if (rfq.step === RequestForQuoteStep.PoReceived) {
    const poReceivedAt = rfq.quote?.timeline.approvedAt ?? null

    return {
      details:
        deliveryMethod === DeliveryMethod.Shipping ? (
          <p>When the order has shipped, select Order Shipped.</p>
        ) : (
          <p>When the order is available at will call, select Ready for Pickup.</p>
        ),
      title: 'PO Received',
      timestamp: poReceivedAt ? `PO Received: ${formatTimestamp(poReceivedAt)}` : null,
      classNames: {
        title: 'text-green-700',
        box: 'border-green-200 bg-green-100',
      },
    }
  }

  if (rfq.step === RequestForQuoteStep.Fulfilling) {
    const readyAt = rfq.quote?.timeline.shippedAt ?? rfq.quote?.timeline.readyForPickupAt ?? null

    return {
      details: (
        <>
          {!invoiceSent && !partiallyShipped && (
            <p>Send an invoice for this order by clicking below.</p>
          )}

          {deliveryMethod === DeliveryMethod.Pickup ? (
            <p>
              We&apos;ll notify you when your customer picks up their order and marks it as
              received.
            </p>
          ) : partiallyShipped ? (
            <p>When the remaining items have shipped, select Order Shipped.</p>
          ) : (
            <p>We&apos;ll notify you when your customer marks their order as received.</p>
          )}
        </>
      ),
      title: 'Fulfilling',
      timestamp: readyAt
        ? deliveryMethod === DeliveryMethod.Pickup
          ? `Ready at Will Call: ${formatTimestamp(readyAt)}`
          : `Shipped: ${formatTimestamp(readyAt)}`
        : null,
      classNames: {
        title: 'text-yellow-600',
        box: 'border-yellow-200 bg-yellow-100',
      },
    }
  }

  if (rfq.step === RequestForQuoteStep.Fulfilled) {
    const receivedAt = rfq.quote?.timeline.receivedAt ?? null

    return {
      details: <p>Your customer marked their order received.</p>,
      title: 'Order Fulfilled',
      timestamp: receivedAt ? `Received: ${formatTimestamp(receivedAt)}` : null,
      classNames: {
        title: 'text-orange-500',
        box: 'border-orange-200 bg-orange-100',
      },
    }
  }

  if (rfq.step === RequestForQuoteStep.Canceled) {
    const refunded = rfq.quote?.state === StoreOrderState.Refunded

    const title = refunded ? (
      'Refunded'
    ) : rfq.nonparticipation ? (
      <>Can&apos;t Participate</>
    ) : (
      'Canceled'
    )

    return {
      details: rfq.nonparticipation ? (
        <p>
          Reason:{' '}
          {rfq.nonparticipation.reason === 'Other'
            ? rfq.nonparticipation.details
            : rfq.nonparticipation.reason}
        </p>
      ) : (
        <p>{refunded ? 'Order refunded' : 'Request canceled'}.</p>
      ),
      title,
      timestamp: rfq.cancelledAt ? `Canceled: ${formatTimestamp(rfq.cancelledAt)}` : null,
      classNames: {
        title: 'text-red-700',
        box: 'border-red-200 bg-red-100',
      },
    }
  }

  throw new Error('Note is not implemented for step')
}

const StepNote = ({
  rfq,
  onReadyForPickup,
  onCreateShipping,
  onAcceptQuote,
  invoiceSent,
}: {
  rfq: Rfq
  onReadyForPickup: () => void
  onCreateShipping: () => void
  onAcceptQuote: () => void
  invoiceSent: boolean
}) => {
  const { quote } = rfq
  const deliveryMethod = quote?.deliveryMethod ?? rfq.requestDetails?.deliveryMethod
  const partiallyShipped = quote?.state === StoreOrderState.PartiallyShipped
  const note = buildNote({ rfq, deliveryMethod, invoiceSent, partiallyShipped })
  const { store } = useSession()

  return (
    <div className={classNames('p-6 rounded-xl border space-y-4 text-center', note.classNames.box)}>
      <div className="space-y-2">
        <h3 className={classNames('text-lg font-medium', note.classNames.title)}>{note.title}</h3>
        <div className="space-y-2 leading-6">{note.details}</div>
        {note.timestamp && <p className="text-xs">{note.timestamp}</p>}
      </div>

      {/* Only Pro Accounts can accept quotes on the customer's behalf */}
      {store.subscription.isPro &&
        rfq.step === RequestForQuoteStep.Quoted &&
        quote?.paymentMethod === PaymentMethod.Direct && (
          <Action.S onClick={onAcceptQuote} className="w-full">
            Accept Quote
          </Action.S>
        )}

      {deliveryMethod === DeliveryMethod.Pickup && rfq.step === RequestForQuoteStep.PoReceived && (
        <Action.P onClick={onReadyForPickup} className="w-full">
          Ready for Pickup
        </Action.P>
      )}

      {deliveryMethod === DeliveryMethod.Shipping &&
        (rfq.step === RequestForQuoteStep.PoReceived ||
          (rfq.step === RequestForQuoteStep.Fulfilling && partiallyShipped)) && (
          <Action.P onClick={onCreateShipping} className="w-full">
            Order Shipped
          </Action.P>
        )}

      {rfq.step === RequestForQuoteStep.Fulfilling &&
        partiallyShipped &&
        quote &&
        !quote.completedAt &&
        quote.refunds.length > 0 && (
          <Link.S to={`/rfqs/${rfq.id}/complete-order`} className="w-full">
            Order Completed
          </Link.S>
        )}

      {quote &&
        quote.vendorFulfilledAt &&
        quote.shipments.length > 0 &&
        quote.proofOfDeliveries.length === 0 && (
          <Link.S to={`/rfqs/${rfq.id}/upload-proof-of-delivery`} className="w-full">
            Upload Proof of Delivery
          </Link.S>
        )}

      {rfq.step === RequestForQuoteStep.Canceled && rfq.nonparticipation && (
        <>
          {rfq.closed ? (
            <p className="prose">The request was canceled and can no longer receive quotes.</p>
          ) : (
            <ParticipateAction />
          )}
        </>
      )}
    </div>
  )
}

export default StepNote

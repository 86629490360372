import { LocationMarkerIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { Controller, useForm } from 'react-hook-form'
import * as Yup from 'yup'

import { RequestForQuoteDocument, Shipment, useUpdateShipmentMutation } from '@/dealers/_gen/gql'
import Action from '@/gf/components/Action'
import CloseModalButton from '@/gf/components/CloseModalButton'
import Field from '@/gf/components/Field'
import Modal from '@/gf/components/ModalNext'
import ShippingCarrierInput from '@/gf/components/next/ShippingCarrierInput'
import TextField from '@/gf/components/TextField'
import useMsgs from '@/gf/hooks/useMsgs'
import FormM from '@/gf/modules/Form'

const formSchema = Yup.object().shape({
  trackingNumber: Yup.string().trim().label('Tracking Number').required().nullable(),
  shippingCarrier: Yup.string().label('Shipping Carrier').required().nullable(),
})

type Form = {
  trackingNumber: string | null
  shippingCarrier: string | null
}

const defaultValues = {
  trackingNumber: null,
  shippingCarrier: null,
}

const AddShipmentInfoModal = ({
  open,
  onClose,
  shipment,
  storeOrderId,
}: {
  open: boolean
  shipment: Shipment
  storeOrderId: string
  onClose: () => void
}) => {
  const [_, msgs] = useMsgs()

  const [updateShipment, { loading: performing }] = useUpdateShipmentMutation({
    refetchQueries: [RequestForQuoteDocument],
  })

  const { control, setValue, register, handleSubmit, formState, reset } = useForm<Form>({
    defaultValues,
    resolver: yupResolver(formSchema),
  })

  const resetAndClose = () => {
    reset()
    onClose()
  }

  return (
    <Modal open={open} onClose={resetAndClose}>
      <CloseModalButton onClick={resetAndClose} className="absolute top-2 right-2" />

      <h2 className="font-medium text-xl flex items-center gap-x-4 border-b border-slate-2 00 px-4 py-3">
        <LocationMarkerIcon className="h-7 inline-block" /> Add tracking information
      </h2>

      <form
        onSubmit={handleSubmit((form) => {
          updateShipment({
            variables: {
              shipmentId: shipment.id,
              trackingNumber: form.trackingNumber,
              shippingCarrier: form.shippingCarrier,
              storeOrderId,
              trackingLink: null,
            },
          })
            .then(() => {
              msgs.add('Tracking information added', 'positive')
              resetAndClose()
            })
            .catch(() => {
              msgs.add('Error adding tracking information. Please contact support.', 'negative')
            })
        })}
        className="flex flex-col gap-y-4"
      >
        <div className="bg-slate-50 p-4 space-y-3">
          <div className="grid grid-cols-2 gap-x-4">
            <Controller
              control={control}
              name="shippingCarrier"
              render={({ field, fieldState }) => (
                <Field label="Shipping Carrier" errors={fieldState.error?.message}>
                  <ShippingCarrierInput
                    value={field.value}
                    onChange={(carrier) =>
                      setValue('shippingCarrier', carrier, {
                        shouldValidate: FormM.shouldValidate(formState.errors),
                      })
                    }
                  />
                </Field>
              )}
            />
            <TextField
              label="Tracking Number"
              {...register('trackingNumber')}
              placeholder="i.e. 134509253094, 1ZP92387"
              errors={formState.errors?.trackingNumber?.message}
            />
          </div>
        </div>

        {shipment.items.map(({ lineItem, quantity }, index) => (
          <div
            key={lineItem.id}
            className={classNames(
              'px-4 pb-4 flex border-b',
              index === shipment.items.length - 1 && 'border-b-0'
            )}
          >
            <div className="flex flex-col grow">
              <div className="flex flex-row justify-between items-center gap-x-2">
                <div className="flex-grow">
                  <p className="font-medium">{lineItem.productName}</p>
                  <p className="text-sm">{lineItem.productMpn}</p>
                </div>
                <div className="flex items-center gap-x-2 whitespace-nowrap">
                  <p>{quantity}</p>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="flex justify-end gap-x-4 px-4 pb-4">
          <Action.S type="button" onClick={resetAndClose}>
            Cancel
          </Action.S>
          <Action.P type="submit" performing={performing} disabled={performing}>
            Add Tracking
          </Action.P>
        </div>
      </form>
    </Modal>
  )
}

export default AddShipmentInfoModal

import Page from '@/gf/components/Page'
import useMsgs from '@/gf/hooks/useMsgs'
import {
  completesCreateQuoteFromScratch,
  initiatesCreateQuoteFromScratch,
} from '@/gf/modules/GrammarEvents'
import { cleanGraphQlInput } from '@/gf/modules/GraphQL'
import { useNavigate } from 'react-router-dom'
import { DeliveryMethod, useStoreOrderRequestLazyQuery } from '../_gen/gql'
import Frame from '../components/Frame'
import useSession from '../hooks/useSession'
import Urls from '../modules/Urls'
import QuoteBuilder from './Request/QuoteBuilder'
import { OnErrorCallback } from './Request/QuoteBuilder/Errors'
import QuoteUploadModal from './Request/QuoteUpload/QuoteUploadModal'
import QuoteUploadProvider from './Request/QuoteUpload/QuoteUploadProvider'
import useQuoteUpload from './Request/QuoteUpload/useQuoteUpload'
import QuoteUploadBar from './Request/QuoteUpload/QuoteUploadBar'

const CreateQuote = () => {
  const navigate = useNavigate()
  const { store } = useSession()
  const [_, msgs] = useMsgs()
  const [getStoreOrderRequest] = useStoreOrderRequestLazyQuery()
  const quoteUpload = useQuoteUpload()

  const onQuoteSuccess = async (storeOrderId: string) => {
    const { data: storeOrderRequestData } = await getStoreOrderRequest({
      variables: { storeOrderId },
    })

    completesCreateQuoteFromScratch({ storeOrderId })

    const to = storeOrderRequestData?.storeOrder?.request?.id
      ? `${Urls.requestForQuote(storeOrderRequestData.storeOrder.request.id)}?quoteSubmitted=1`
      : `/orders/${storeOrderId}`

    navigate(to)
  }

  const onError: OnErrorCallback = (message) => msgs.add(message, 'negative')

  const canCreateQuote = store.subscription.isPro

  const initialValues = {
    customerContactId: null,
    shippingCost: null,
    taxCost: null,
    customerDiscount: null,
    quoteNumber: null,
    deliveryMethod: DeliveryMethod.Pickup,
    items: [
      {
        partNumber: '',
        description: '',
        unitPrice: null,
        quantity: 1,
        inStock: true,
        externalId: null,
        taskNumber: null,
        suggestion: null,
        availabilityDate: null,
        rfqPartId: null,
        rfqPartNumber: null,
      },
    ],
    pickupAddress: store.address ? cleanGraphQlInput(store.address) : null,
    shippingAddress: null,
    neededByDate: null,
    urgency: null,
    comments: '',
  }

  return (
    <>
      <QuoteUploadModal
        uploadStage={quoteUpload.uploadStage}
        error={quoteUpload.error}
        onClose={quoteUpload.onDismiss}
      />

      <Frame>
        <Page className="max-w-[66.5rem] mx-auto w-full pt-8 space-y-8">
          <h2 className="text-xl font-medium leading-7 max-w-lg ">Create Quote</h2>

          <QuoteBuilder
            initialValues={initialValues}
            images={[]}
            onSuccess={onQuoteSuccess}
            actions={
              <QuoteUploadBar
                onError={quoteUpload.onError}
                onUploadComplete={quoteUpload.onUploadComplete}
                onUploadStarted={quoteUpload.onUploadStarted}
                rfqId={quoteUpload.rfqId}
              />
            }
            onError={onError}
            onCancel={() => navigate('/rfqs')}
            disabled={!canCreateQuote}
            customerFeeRate={null}
            onTouched={initiatesCreateQuoteFromScratch}
            showWhenCustomerDiscountAutoFilled
          />
        </Page>
      </Frame>
    </>
  )
}

const CreateQuoteWithQuoteUploadProvider = () => (
  <QuoteUploadProvider rfqId={null}>
    <CreateQuote />
  </QuoteUploadProvider>
)

export default CreateQuoteWithQuoteUploadProvider

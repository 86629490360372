import CircleProgress from '@/buyers/pages/Order/YourOrder/CircleProgress'
import { DeliveryMethod, RequestForQuoteQuery } from '@/dealers/_gen/gql'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import Money from '@/gf/modules/Money'
import { InformationCircleIcon } from '@heroicons/react/outline'
import { ArrowCircleLeftIcon, CheckCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'
import { ReactNode } from 'react'

type StoreOrder = NonNullable<NonNullable<RequestForQuoteQuery['requestForQuote']>['quote']>

type LineItem = StoreOrder['lineItems'][number]

const willSomeNotShip = (storeOrder: StoreOrder) =>
  storeOrder.deliveryMethod === DeliveryMethod.Shipping &&
  storeOrder.completedAt &&
  storeOrder.refunds.length > 0

const LineItems = ({
  storeOrder,
  deliveryMethod,
  title,
  subtitle,
  className,
  showPrices,
  ...props
}: {
  storeOrder: StoreOrder
  lineItems: LineItem[]
  deliveryMethod: DeliveryMethod
  title: ReactNode
  subtitle?: string
  className?: string
  showPrices: boolean
}) => {
  const someWillNotShip = willSomeNotShip(storeOrder)

  const lineItems = props.lineItems.map((i) => {
    const refundedQuantity = someWillNotShip ? i.quantity - i.shippedQuantity : 0
    return { ...i, refundedQuantity, shippableQuantity: i.quantity - refundedQuantity }
  })

  return (
    <div className={className}>
      <h2 className="font-medium text-lg flex items-center gap-x-1.5">{title}</h2>
      {subtitle && <p className="py-3">{subtitle}</p>}
      <div className="w-full overflow-x-auto fancy-scroll">
        <table className="w-full text-sm lg:text-base text-left">
          <thead className="hidden lg:table-header-group border-b font-medium text-gray-500">
            <tr className="h-16 divide-x border-gray-200 [&>th]:align-middle">
              <th className="pr-3 py-2 w-28 min-w-28">Part Number</th>
              <th className="px-3 py-2">Description</th>
              <th className="px-3 py-2 w-16">Qty</th>
              {showPrices && (
                <>
                  <th className="px-3 py-2 w-24">Price</th>
                  <th className="px-3 py-2 w-30">Total</th>
                  <th className="py-2 pl-3 min-w-48">Availability</th>
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {lineItems.map((item) => (
              <tr
                key={item.id}
                className={classNames(
                  'border-b last:border-0 flex flex-col gap-y-1.5',
                  'lg:table-row lg:divide-x [&>td]:align-middle'
                )}
              >
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Part_Number'] before:font-medium pt-4 break-all",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:pr-3'
                  )}
                >
                  {item.rfqPart && item.rfqPart.mpn && item.rfqPart.mpn !== item.product.mpn ? (
                    <SimpleTooltip text={`Requested: ${item.rfqPart.mpn}`} placement="bottom">
                      <span className="flex items-center">
                        {item.product.mpn}{' '}
                        <InformationCircleIcon className="ml-1 inline-block w-4 h-4 text-gray-700" />
                      </span>
                    </SimpleTooltip>
                  ) : (
                    <>{item.product.mpn}</>
                  )}
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Description'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  <SimpleTooltip
                    placement="bottom-start"
                    text={item.product.name}
                    className="lg:max-w-56"
                  >
                    <span className="inline-block lg:max-w-[20ch] lg:truncate lg:text-ellipsis leading-5 text-left">
                      {item.product.name}
                    </span>
                  </SimpleTooltip>
                </td>
                <td
                  className={classNames(
                    "grid grid-cols-[12ch_auto] before:content-['Quantity'] before:font-medium",
                    'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                  )}
                >
                  {item.quantity}
                </td>
                {showPrices && (
                  <>
                    <td
                      className={classNames(
                        "grid grid-cols-[12ch_auto] before:content-['Unit_Price'] before:font-medium",
                        'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                      )}
                    >
                      {!Money.equals(item.unitPrice, item.discountedUnitPrice) && (
                        <span className="line-through text-gray-500 ">
                          {Money.format(item.unitPrice)}
                        </span>
                      )}

                      <span>{Money.format(item.discountedUnitPrice)}</span>
                    </td>
                    <td
                      className={classNames(
                        "grid grid-cols-[12ch_auto] before:content-['Total'] before:font-medium ",
                        'lg:table-cell lg:before:content-none lg:py-2 lg:px-3'
                      )}
                    >
                      {Money.format(item.extendedPrice)}
                    </td>

                    <td className="block lg:table-cell pb-4 lg:py-2 lg:px-3">
                      {item.shippedQuantity === 0 &&
                        item.shippableQuantity !== 0 &&
                        (item.inStock || item.availableAt) && (
                          <span className="inline-flex flex-col">
                            <span
                              className={classNames(
                                'font-medium inline-block leading-5',
                                !item.inStock && 'text-red-500'
                              )}
                            >
                              {item.inStock ? 'In stock' : 'Backordered'}
                            </span>

                            <span className="inline-block leading-5 text-sm">
                              {item.inStock ? (
                                deliveryMethod === DeliveryMethod.Shipping ? (
                                  <>Available to ship today</>
                                ) : (
                                  <>Available at will call today</>
                                )
                              ) : (
                                `Available at ${item.availableAt?.toFormat('DDDD')}`
                              )}
                            </span>
                          </span>
                        )}

                      {item.shippedQuantity > 0 &&
                        item.shippedQuantity < item.shippableQuantity && (
                          <div className="flex gap-1.5 items-center text-gray-700 text-sm font-medium">
                            <CircleProgress
                              progress={(item.shippedQuantity / item.shippableQuantity) * 100}
                              className="w-4 h-4"
                            />
                            {item.shippedQuantity.toLocaleString()}/
                            {item.shippableQuantity.toLocaleString()}{' '}
                            {deliveryMethod === DeliveryMethod.Shipping
                              ? 'shipped'
                              : 'at will call'}
                          </div>
                        )}

                      {item.shippedQuantity >= item.shippableQuantity && (
                        <div className="flex gap-1 items-center text-gray-700 text-sm font-medium">
                          <CheckCircleIcon className="-p-0.5 w-5 h-5 inline-flex shrink-0 text-blue-600" />
                          {item.refundedQuantity > 0 ? item.shippedQuantity : 'All'} shipped
                        </div>
                      )}

                      {item.refundedQuantity > 0 && (
                        <SimpleTooltip
                          text={`${
                            item.refundedQuantity === 1
                              ? 'This part was'
                              : `These ${item.refundedQuantity} parts were`
                          } refunded, so ${
                            item.refundedQuantity === 1 ? 'it' : 'they'
                          } won't be shipped.`}
                        >
                          <div className="flex gap-1 items-center text-gray-700 text-sm font-medium">
                            <ArrowCircleLeftIcon className="-p-0.5 w-5 h-5 inline-flex shrink-0 text-red-700" />
                            {item.refundedQuantity.toLocaleString()} refunded
                          </div>
                        </SimpleTooltip>
                      )}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default LineItems

import { RequestForQuoteQuery } from '@/dealers/_gen/gql'
import { createContext, useContext } from 'react'

type Rfq = Exclude<RequestForQuoteQuery['requestForQuote'], null>
export type Quote = Exclude<Rfq['quote'], null>

export const RfqContext = createContext<
  { rfqId: string; rfq: Rfq; refetch: () => Promise<unknown>; invoiceSent: boolean } | undefined
>(undefined)

export const useRfqContext = () => {
  const context = useContext(RfqContext)
  if (!context) throw new Error('must be used within provider')
  return context
}

import { useStoreUsersListQuery, useSubscriptionQuery } from '@/dealers/_gen/gql'
import Bullet from '@/dealers/components/Bullet'
import useSession from '@/dealers/hooks/useSession'
import Action from '@/gf/components/Action'
import Box from '@/gf/components/Box'
import Ghost from '@/gf/components/Ghost'
import { SimpleTooltip } from '@/gf/components/next/Tooltip'
import { Table, Tbody, Td, Th, Thead, Tr } from '@/gf/components/Table'
import Phone from '@/gf/modules/Phone'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Section from './Section'
import AddUserModal from './Users/AddUserModal'

const Loading = () => (
  <>
    {[1, 2].map((n) => (
      <Tr key={n}>
        <Td>
          <span className="space-y-1">
            <Ghost className="block h-4 w-2/5" />
            <Ghost className="block h-4 w-3/5" />
          </span>
        </Td>
        <Td width="60px">
          <Ghost className="block h-4 w-32" />
        </Td>
      </Tr>
    ))}
  </>
)

const Users = () => {
  const navigate = useNavigate()
  const { store } = useSession()
  const { data, loading, refetch } = useStoreUsersListQuery()
  const { data: subscription } = useSubscriptionQuery({ variables: { storeId: store.id } })
  const proSubscription = subscription?.proSubscription
  const users = data?.session.store?.users ?? []
  const showAddButton = store.subscription.isPro

  return (
    <>
      <div className="w-full max-w-screen-lg">
        <Box className="px-6 py-6 ">
          <Section
            title="Users"
            subtitle="Manage and add users to your organization's account on Gearflow."
          >
            <div className="flex justify-end pb-3">
              {showAddButton ? (
                <Action.P onClick={() => navigate('add')}>Add User</Action.P>
              ) : proSubscription ? (
                <SimpleTooltip
                  placement="bottom-start"
                  text="You have reached your license limit, please contact sales."
                  className="lg:max-w-56"
                >
                  <Action.P disabled onClick={() => navigate('add')}>
                    Add User
                  </Action.P>
                </SimpleTooltip>
              ) : (
                <Action.P onClick={() => navigate('add')}>Add User</Action.P>
              )}
            </div>
            <Table className="border">
              <Thead>
                <Tr>
                  <Th>User</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Loading />
                ) : (
                  users.map((u) => (
                    <Tr key={u.id}>
                      <Td>
                        <span className="flex flex-col">
                          <span className="font-medium">
                            {u.name || <span className="text-gray-500 italic">(not set)</span>}{' '}
                            {u.role === 'owner' && (
                              <span className="text-xs font-normal italic text-slate-600 capitalize pl-2">
                                {u.role}
                              </span>
                            )}
                          </span>
                          <span className="text-sm text-slate-500">{u.email}</span>
                          {u.phoneNumber && (
                            <span className="text-sm text-slate-500">
                              {Phone.format(u.phoneNumber)}
                            </span>
                          )}
                        </span>
                      </Td>
                      <Td>
                        {u.claimed ? (
                          <>
                            <Bullet className="bg-emerald-500 mr-1" size="sm" />
                            Active
                          </>
                        ) : (
                          <>
                            <Bullet className="bg-yellow-400 mr-1" size="sm" />
                            Pending
                          </>
                        )}
                      </Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Section>
        </Box>
      </div>

      <Routes>
        <Route
          path="add"
          element={<AddUserModal open onClose={() => navigate(-1)} onSuccess={refetch} />}
        />
      </Routes>
    </>
  )
}

export default Users

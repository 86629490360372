import { DeliveryMethod, RequestForQuoteStep, useCustomerContactQuery } from '@/dealers/_gen/gql'
import useSession from '@/dealers/hooks/useSession'
import { MultiButtons } from '@/gf/components/MultiButton'
import useMsgs from '@/gf/hooks/useMsgs'
import { completesCreateQuote, initiatesCreateQuote } from '@/gf/modules/GrammarEvents'
import { cleanGraphQlInput } from '@/gf/modules/GraphQL'
import MachineInformation from '../MachineInformation'
import QuoteBuilder from '../QuoteBuilder'
import { QuoteUploadProps } from '../QuoteUpload/QuoteUploadButton'
import StepNote from '../StepNote'
import { viewOriginalRequestAction } from '../ViewOriginalRequest'
import { useRfqContext } from '../context'
import QuoteUploadBar from '../QuoteUpload/QuoteUploadBar'

const CreateQuote = ({
  quoteUpload,
  onReadyForPickup,
  onCreateShipping,
  onAcceptQuote,
  ...props
}: {
  onQuoteSuccess: () => void
  quoteUpload: Pick<QuoteUploadProps, 'onError' | 'onUploadComplete' | 'onUploadStarted'>
  onReadyForPickup: () => void
  onCreateShipping: () => void
  onAcceptQuote: () => void
}) => {
  const { rfq, refetch, invoiceSent } = useRfqContext()
  const { store } = useSession()
  const [_, msgr] = useMsgs()
  const { data } = useCustomerContactQuery({ variables: { customerContactId: rfq.user.id } })

  if (rfq.quote || rfq.step !== RequestForQuoteStep.Inbound) return null

  const customerAddress = rfq.shippingAddress ?? data?.customerContact.account.address ?? null

  const onError = (message: string, needsReload: boolean) => {
    if (needsReload)
      msgr.addWithAction(message, { text: 'Reload the page', callback: refetch }, 'negative')
    else msgr.add(message, 'negative')
  }

  const initialValues = {
    customerContactId: null,
    shippingCost: null,
    taxCost: null,
    customerDiscount: rfq.user.organization.discount,
    quoteNumber: null,
    deliveryMethod: rfq.requestDetails?.deliveryMethod ?? DeliveryMethod.Shipping,
    items: rfq.partRequests.map((pr) => ({
      partNumber: pr.mpn,
      description: pr.description,
      unitPrice: null,
      quantity: pr.quantity,
      inStock: true,
      externalId: pr.externalId,
      taskNumber: pr.taskNumber,
      suggestion: pr.suggestion,
      availabilityDate: null,
      rfqPartId: pr.id,
      rfqPartNumber: pr.mpn,
    })),
    pickupAddress: store.address ? cleanGraphQlInput(store.address) : null,
    shippingAddress: customerAddress ? cleanGraphQlInput(customerAddress) : null,
    comments: null,
    neededByDate: null,
    urgency: null,
  }

  const onQuoteSuccess = (storeOrderId: string) => {
    props.onQuoteSuccess()
    completesCreateQuote({ rfqId: rfq.id, storeOrderId })
  }

  return (
    <QuoteBuilder
      rfqId={rfq.id}
      initialValues={initialValues}
      images={rfq.images.map((i) => i.url)}
      machineDetailsSection={
        <>
          <MachineInformation reqMachine={rfq.machines[0] ?? null} className="px-4" />

          {rfq.partsRequest && (
            <p className="px-4 leading-5 text-sm prose whitespace-pre-wrap">
              <span className="text-gray-900 font-medium">Comments from the customer:</span>{' '}
              {rfq.partsRequest}
            </p>
          )}
        </>
      }
      onSuccess={onQuoteSuccess}
      actions={
        <QuoteUploadBar
          onError={quoteUpload.onError}
          onUploadComplete={quoteUpload.onUploadComplete}
          onUploadStarted={quoteUpload.onUploadStarted}
          rfqId={rfq.id}
          cantParticipateLinkTo="cant-participate"
        />
      }
      onError={onError}
      sideNote={
        <StepNote
          rfq={rfq}
          onReadyForPickup={onReadyForPickup}
          onCreateShipping={onCreateShipping}
          onAcceptQuote={onAcceptQuote}
          invoiceSent={invoiceSent}
        />
      }
      secondaryActions={
        <MultiButtons className="!shadow-sm" items={[viewOriginalRequestAction(rfq)]} />
      }
      showSalesTax={rfq.requestDetails !== null}
      customerFeeRate={!rfq.requestDetails ? store.convenienceFeeRate : null}
      onTouched={() => initiatesCreateQuote({ rfqId: rfq.id })}
      showWhenCustomerDiscountAutoFilled
    />
  )
}

export default CreateQuote
